<template>
  <div class="flex flex-col items-stretch h-[calc(100%-52px)]">
    <loader :is-loading="loading" />
    <upload-box
      class="call-log"
      data-cy="upload-call-recording"
      :accept="ACCEPT"
      @files:add="uploadCallRecording"
    />
  </div>
</template>

<script setup lang="ts">
import { usePromiseWrapper } from "@/hooks/common";
import communicationService from "@/services/modules/communication";
import UploadBox from "@/components/ui/inputs/UploadBox.vue";
import type { CallLog } from "@/models/communicationLogs";

const emit = defineEmits<{
  "callNote:uploaded": [];
}>();

const { callLog } = defineProps<{
  callLog: CallLog;
}>();

const ACCEPT = ".mp3, .wav, .m4a, x-wav";

const { fetchWrapper: uploadCallRecording, loading } = usePromiseWrapper(
  async (files: FileList) => {
    const file = files[0];
    const formData = new FormData();
    formData.append("file", file);
    await communicationService.uploadCallRecording(callLog.id, file);
    emit("callNote:uploaded");
  }
);
</script>

<style scoped>
:deep(.call-log) {
  @apply h-full;
}
</style>
